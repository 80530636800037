export function Youtube() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2.66666C7.20934 2.66666 3.58725 3.36588 3.58725 3.36588L3.57814 3.37629C2.30712 3.57957 1.33334 4.67174 1.33334 5.99999V9.99999V10.0013V14V14.0013C1.33458 14.6356 1.56192 15.2488 1.97454 15.7306C2.38716 16.2124 2.95803 16.5314 3.58465 16.6302L3.58725 16.6341C3.58725 16.6341 7.20934 17.3346 10 17.3346C12.7907 17.3346 16.4128 16.6341 16.4128 16.6341L16.4141 16.6328C17.0414 16.5342 17.6129 16.215 18.0258 15.7326C18.4388 15.2502 18.666 14.6363 18.6667 14.0013V14V10.0013V9.99999V5.99999C18.6657 5.36541 18.4385 4.75196 18.0259 4.26986C17.6133 3.78777 17.0422 3.46862 16.4154 3.36978L16.4128 3.36588C16.4128 3.36588 12.7907 2.66666 10 2.66666ZM8.00001 6.93228L13.3333 9.99999L8.00001 13.0677V6.93228Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Youtube;
