"use client";

// @components
import Link from "next/link";
import Image from "next/image";
import { X, Youtube, Telegram, Button, GoPulseX } from "@/components";

// @hooks
import { usePathname } from "next/navigation";

const SOCIAL_LINKS = [
  {
    icon: X,
    name: "X (Formerly known as Twitter)",
    link: "https://twitter.com/lookintohex",
  },
  {
    icon: Youtube,
    name: "youtube",
    link: "https://www.youtube.com/gerardog",
  },
  {
    icon: Telegram,
    name: "telegram",
    link: "https://t.me/lookintohex",
  },
];

export function Navbar() {
  const pathname = usePathname();

  const isAuth = pathname.startsWith("/auth");

  if (isAuth) {
    return null;
  }

  return (
    <div className="flex min-h-[82px] flex-wrap items-center justify-between gap-x-8 gap-y-6 rounded-xl border-2 border-secondary bg-primary p-6">
      <div className="flex flex-grow items-center justify-between gap-8">
        <div className="flex items-center">
          <Link href="/" className="flex items-center gap-4 sm:ml-0.5">
            <div className="w-max rounded-md bg-secondary p-2">
              <Image
                src="/brand-logo.png"
                alt="LookIntoHex logo"
                width={400}
                height={400}
                className="h-[30px] w-[30px]"
              />
            </div>
            <span className="hidden text-lg font-medium text-white sm:block">
              LookIntoHex
            </span>
          </Link>
        </div>
        <div className="mr-2 flex items-center gap-6 sm:mr-0">
          {SOCIAL_LINKS.map(({ icon: Icon, link, name }) => (
            <Link
              key={link}
              href={link}
              target="_blank"
              aria-label={name}
              className="text-white transition-colors duration-300 hover:text-hex-400"
            >
              <Icon />
            </Link>
          ))}
        </div>
      </div>
      <div className="flex flex-grow items-center gap-2 sm:flex-grow-0">
        <GoPulseX />
        <Link
          target="_blank"
          className="w-full sm:w-auto"
          href="https://gopulse.com/hex"
        >
          <Button variant="primary" className="w-full" aria-label="Stake Hex">
            Stake Hex
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
