import { LegendProps } from "recharts";

export function legendConfig(options?: LegendProps) {
  return {
    iconSize: 16,
    iconType: "diamond",
    formatter: (value: string) => (
      <span className="mt-6 translate-y-px text-sm inline-block">{value}</span>
    ),
    ...options,
  } as any;
}

export default legendConfig;
