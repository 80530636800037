"use client";

import * as React from "react";

// @utils
import { cn } from "@/utils";

interface ChartCardProps {
  title: string;
  isLoading: boolean;
  description: string;
  icon: React.ElementType;
  children: React.ReactNode;
}

export function ChartCard({
  title,
  isLoading,
  description,
  icon: Icon,
  children,
}: ChartCardProps) {
  return (
    <div className="group relative h-full rounded-xl">
      <div className="absolute -inset-[2px] z-0 h-[calc(100%+4px)] w-[calc(100%+4px)] rounded-xl bg-[linear-gradient(to_right,#ffdb01,#ff851f,#ff3d3d,#ff0f6f,#ff00ff)] opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
      <div
        className={cn({
          "relative isolate z-10 h-full overflow-hidden rounded-xl before:absolute before:inset-0 before:z-50 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/5 before:to-transparent":
            isLoading,
        })}
      >
        <div className="group relative z-10 min-h-[22rem] overflow-hidden rounded-xl border-2 border-secondary bg-primary">
          <div className="pointer-events-none absolute inset-0 z-50 flex scale-95 flex-col justify-between rounded-[10px] bg-primary/25 p-6 opacity-0 backdrop-blur-lg backdrop-saturate-200 transition-all duration-500 group-hover:pointer-events-auto group-hover:scale-100 group-hover:opacity-100">
            <Icon className="h-16 w-16 text-white" strokeWidth={1} />
            <div className="leading-none">
              <h2 className="mb-4 text-2xl font-medium text-white">{title}</h2>
              <p className="text-base font-normal text-white/80">
                {description}
              </p>
            </div>
          </div>
          <div className="relative grid h-full min-h-[22rem] w-full place-items-center">
            {!isLoading && children}
            <h2 className="absolute bottom-5 right-7 text-2xl font-bold text-white md:text-3xl xl:text-4xl">
              {title}
            </h2>
          </div>
        </div>
      </div>
      <div className="absolute left-2/4 top-2/4 h-full w-full -translate-x-2/4 -translate-y-2/4 scale-105 rounded-xl bg-[linear-gradient(to_right,#ffdb01,#ff851f,#ff3d3d,#ff0f6f,#ff00ff)] opacity-0 blur-lg transition-transform duration-500 group-hover:opacity-20" />
    </div>
  );
}

export default ChartCard;
