import { XAxisProps } from "recharts";

export function xAxisConfig(options?: XAxisProps) {
  return {
    height: 0,
    stroke: "transparent",
    color: "transprent",
    ...options,
  } as any;
}

export default xAxisConfig;
