let lastColor = "";

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function isSameShade(color1: string, color2: string) {
  const shadeDifference = 16; // Define your own shade difference
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  if (!rgb1 || !rgb2) {
    return false;
  }

  return (
    Math.abs(rgb1.r - rgb2.r) < shadeDifference &&
    Math.abs(rgb1.g - rgb2.g) < shadeDifference &&
    Math.abs(rgb1.b - rgb2.b) < shadeDifference
  );
}

export function generateColor() {
  let color;

  do {
    color = Math.floor(
      Math.random() * (16777215 - 10485760) + 10485760
    ).toString(16);
    color = "#" + ("000000" + color).substring(("000000" + color).length - 6);
  } while (isSameShade(color, lastColor));

  lastColor = color;
  return color;
}

export default generateColor;
