"use client";

import * as React from "react";

// @utils
import { cn } from "@/utils";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "simple";
  iconOnly?: boolean;
  className?: string;
  contentClassName?: string;
  children: React.ReactNode;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primary",
      iconOnly,
      className,
      contentClassName,
      children,
      ...rest
    },
    ref,
  ) => {
    const STYLES = {
      base: cn(
        "whitespace-nowrap relative z-10 rounded-full py-3 border text-white text-sm transition-all duration-300 group-hover:border-transparent backdrop-blur-xl backdrop-saturate-200 px-6",
        {
          "px-3": iconOnly,
        },
      ),
      primary:
        "bg-secondary shadow-md shadow:black/10 border-secondary group-hover:bg-primary/90",
      secondary: "bg-primary/90 border-secondary",
      simple:
        "bg-secondary shadow-none border-secondary rounded-md !px-2.5 !py-1.5 hover:bg-secondary/50 flex items-center gap-2",
    };

    const isSimple = variant === "simple";

    return (
      <button
        ref={ref}
        type="button"
        {...rest}
        className={cn(
          "group relative disabled:pointer-events-none disabled:opacity-70",
          className,
        )}
      >
        {!isSimple && (
          <div
            aria-hidden="true"
            className="absolute -inset-px z-0 h-[calc(100%+2px)] w-[calc(100%+2px)] rounded-full bg-[linear-gradient(to_right,#ffdb01,#ff851f,#ff3d3d,#ff0f6f,#ff00ff)] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
          />
        )}
        <div
          className={cn(`${STYLES.base} ${STYLES[variant]}`, contentClassName)}
        >
          {children}
        </div>
        {!isSimple && (
          <div
            aria-hidden="true"
            className="absolute left-2/4 top-2/4 h-full w-full -translate-x-2/4 -translate-y-2/4 scale-0 rounded-full bg-[linear-gradient(to_right,#ffdb01,#ff851f,#ff3d3d,#ff0f6f,#ff00ff)] opacity-30 blur-lg transition-transform duration-300 group-hover:scale-105"
          />
        )}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;
