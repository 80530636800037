"use client";

import * as React from "react";

// @utils
import { cn } from "@/utils";

interface ParagraphProps {
  className?: string;
  children: React.ReactNode;
}

export function Paragraph({ children, className }: ParagraphProps) {
  return (
    <p
      className={cn(
        "mb-12 leading-relaxed text-foreground [text-wrap:_balance]",
        className,
      )}
    >
      {children}
    </p>
  );
}

export default Paragraph;
