"use client";
import * as React from "react";

// @utils
import { cn } from "@/utils";

interface HeadingProps {
  className?: string;
  children: React.ReactNode;
}

export function Heading({ children, className }: HeadingProps) {
  return (
    <h1
      className={cn(
        "mb-6 text-3xl font-semibold text-white md:text-4xl lg:text-5xl",
        className,
      )}
    >
      {children}
    </h1>
  );
}

export default Heading;
