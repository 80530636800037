"use client";

// @utils
import { cn } from "@/utils";

export function BorderContainer({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "overflow-hidden rounded-2xl border-2 border-secondary",
        className,
      )}
    >
      {children}
    </div>
  );
}
