"use client";

// @utils
import { cn } from "@/utils";

interface MiniCardProps {
  title: string;
  isLoading?: boolean;
  children: React.ReactNode;
}

export function MiniCard({ title, children, isLoading }: MiniCardProps) {
  return (
    <div className="relative rounded-xl">
      <div
        className={cn({
          "relative isolate z-10 h-full overflow-visible rounded-xl before:absolute before:inset-0 before:z-50 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/5 before:to-transparent":
            isLoading,
        })}
      >
        <div
          className={cn(
            "w-full rounded-xl border-2 border-secondary bg-secondary/50 p-6",
            {
              "animate-pulse": isLoading,
            },
          )}
        >
          <h2 className="flex items-center justify-center gap-4 text-center text-lg font-semibold text-white">
            {isLoading ? (
              <>&nbsp;</>
            ) : (
              <>
                <span className="text-foreground">{title}</span>
                {children}
              </>
            )}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default MiniCard;
