"use client";

import * as React from "react";

// @hooks
import { useWindowSize } from "usehooks-ts";

// masonry item component
export interface MasonryGridProps extends React.HTMLAttributes<HTMLDivElement> {
  gutter?: number;
  children: React.ReactNode;
  columns?: number | Record<number, number>;
}

export const MasonryGrid = React.forwardRef<HTMLDivElement, MasonryGridProps>(
  ({ columns = 2, gutter = 4, children, ...props }, ref) => {
    const { width = 0 } = useWindowSize();

    const columnsCount = React.useMemo(() => {
      if (typeof columns === "number") {
        return columns;
      }

      const breakpoints = Object.keys(columns).sort(
        (a, b) => Number(a) - Number(b),
      ) as any;

      let count = breakpoints.length > 0 ? columns[breakpoints[0]] : 2;

      breakpoints.forEach((breakPoint: any) => {
        if (Number(breakPoint) < width) {
          count = columns[breakPoint];
        }
      });

      return count;
    }, [width, columns]);

    function getColumns() {
      const totlaColumns: any = Array.from({ length: columnsCount }, () => []);

      if (Array.isArray(children)) {
        children.forEach((child, index) => {
          if (child && React.isValidElement(child)) {
            totlaColumns[index % columnsCount].push(child);
          }
        });
      } else {
        totlaColumns[0].push(children);
      }

      return totlaColumns;
    }

    function renderColumns() {
      return getColumns().map((column: any, idx: any) => (
        <div
          key={idx}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "stretch",
            flex: 1,
            width: 0,
            gap: gutter,
          }}
        >
          {column.map((item: any) => item)}
        </div>
      ));
    }

    return (
      <div
        {...props}
        ref={ref}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "stretch",
          boxSizing: "border-box",
          width: "100%",
          gap: gutter,
          ...props?.style,
        }}
      >
        {renderColumns()}
      </div>
    );
  },
);

MasonryGrid.displayName = "MasonryGrid";

export default MasonryGrid;
