interface ToggleHideChart {
  event: { dataKey: string };
  state: string[];
  setState: React.Dispatch<React.SetStateAction<string[]>>;
}

export function toggleHideChart({ event, state, setState }: ToggleHideChart) {
  if (state.includes(event.dataKey)) {
    setState((prev: string[]) => prev.filter((key) => key !== event.dataKey));
  } else {
    setState([...state, event.dataKey]);
  }
}

export default toggleHideChart;
