"use client";

// @utils
import { cn } from "@/utils";

export function ChartTable({
  values,
  className,
}: {
  values: Record<string, string>;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "divide-y divide-secondary rounded-lg border border-secondary bg-secondary/50",
        className,
      )}
    >
      {Object.entries(values).map(([key, val], index) => (
        <div
          key={index}
          className="grid grid-cols-2 divide-x divide-secondary text-white"
        >
          <span className="p-4">{key}</span>
          <span className="p-4">{val}</span>
        </div>
      ))}
    </div>
  );
}
