import * as React from "react";

// @components
import Link from "next/link";
import Image from "next/image";

// @utils
import parse from "html-react-parser";
import { getTweet } from "react-tweet/api";
import { type TweetCoreProps, enrichTweet } from "react-tweet";

async function Tweet({ id, onError }: TweetCoreProps) {
  const tweet = id
    ? await getTweet(id).catch((err) => {
        if (onError) {
          onError(err);
        } else {
          console.error(err);
        }
      })
    : undefined;

  if (!tweet) {
    return (
      <div className="prose flex h-[20rem] break-inside-avoid items-center rounded-lg border-2 border-secondary bg-secondary/30 bg-clip-padding p-6 pb-4 text-center text-sm">
        <p>There was an error loading this tweet.</p>
      </div>
    );
  }

  const tweetData = enrichTweet(tweet);

  return (
    <div className="cursor-pointer overflow-hidden rounded-xl border-2 border-secondary p-6">
      <div className="flex items-center gap-4">
        <Image
          width={768}
          height={768}
          quality={100}
          src={tweetData.user.profile_image_url_https}
          alt={tweetData.user.name}
          className="h-10 w-10 rounded-full border border-white object-cover"
        />
        <div className="flex flex-col">
          <div className="flex items-center gap-1">
            <h3 className="max-w-[200px] truncate text-sm font-semibold text-white">
              {tweetData.user.name}
            </h3>
            {!tweetData.user.is_blue_verified && (
              <svg
                viewBox="0 0 22 22"
                className="!h-4 !w-4 shrink-0 fill-blue-500"
              >
                <g>
                  <path d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"></path>
                </g>
              </svg>
            )}
          </div>
          <p className="text-sm text-foreground">
            {tweetData.user.screen_name}
          </p>
        </div>
      </div>
      <p className="mb-4 mt-6 text-foreground">
        {tweetData.entities.map((item, i) => {
          switch (item.type) {
            case "hashtag":
            case "mention":
            case "url":
            case "symbol":
              return (
                <Link
                  key={i}
                  href={item.href}
                  target="_blank"
                  className="text-blue-500"
                >
                  {item.text}
                </Link>
              );
            case "media":
              return;
            default:
              return parse(item.text);
          }
        })}
      </p>
      <div className="flex items-center justify-between gap-4">
        <span className="block text-sm text-white">
          {new Date(tweetData.created_at).toLocaleDateString()}
        </span>
        <Link
          href={`https://twitter.com/peduarte/status/${tweetData.id_str}`}
          target="_blank"
          className="flex items-center gap-1 text-sm font-medium text-white transition-colors duration-300 ease-in hover:text-hex-400"
        >
          See on X
        </Link>
      </div>
    </div>
  );
}

export function TestimonialCard(props: TweetCoreProps) {
  return (
    <React.Suspense>
      <Tweet {...props} />
    </React.Suspense>
  );
}
