"use client";

import * as React from "react";

// @utils
import { cn } from "@/utils";

interface SubHeadingProps {
  className?: string;
  children: React.ReactNode;
}

export function SubHeading({ children, className }: SubHeadingProps) {
  return (
    <h2
      className={cn(
        "mb-8 text-2xl font-semibold text-white lg:text-3xl",
        className,
      )}
    >
      {children}
    </h2>
  );
}

export default SubHeading;
