import { pgTable, text, boolean, timestamp } from "drizzle-orm/pg-core";

export const subscription = pgTable("subscriptions", {
  email: text("email").primaryKey(),
  customer_id: text("customer_id").notNull(),
  subscription_id: text("subscription_id").notNull(),
  created_at: timestamp("created_at").notNull().defaultNow(),
  ended_at: timestamp("ended_at"),
  is_cancelled: boolean("is_cancelled").notNull().default(false),
});

export const newsletter = pgTable("newsletters", {
  email: text("email").primaryKey(),
  created_at: timestamp("created_at").notNull().defaultNow(),
  is_subscribed: boolean("is_subscribed").notNull().default(false),
});
