"use client";

import * as React from "react";

// @components
import Link from "next/link";
import Image from "next/image";
import { Button, Ethereum, X } from "@/components";

// @icons
import {
  Square2StackIcon,
  CheckIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

// @hooks
import { useForm } from "react-hook-form";
import { usePathname } from "next/navigation";
import { useCopyToClipboard } from "usehooks-ts";

// @utils
import { z } from "zod";
import { cn } from "@/utils";
import { toast } from "sonner";
import { createInsertSchema } from "drizzle-zod";
import { zodResolver } from "@hookform/resolvers/zod";

// @schemas
import { newsletter } from "@/drizzle/schema";

// @actions
import { collectionEmail } from "@/supabase/admin";

const formSchema = createInsertSchema(newsletter, {
  email: z.string().email({
    message: "Invalid email address",
  }),
}).pick({
  email: true,
});

type FormType = z.infer<typeof formSchema>;

export function Footer() {
  const pathname = usePathname();
  const [_, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  React.useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 4000);
    }
  }, [isCopied]);

  async function onSubmit(values: FormType) {
    setIsLoading(true);

    const formData = new FormData();

    for (const key in values) {
      formData.append(key, (values as any)[key]);
    }

    const response = await collectionEmail(formData);

    if (response === "success") {
      reset();
      setIsLoading(false);
      toast.success("Email collected successfully.");
    } else if (response === "error") {
      setIsLoading(false);
      toast.error("An error occurred! Please try again.");
    }
  }

  const emailError = errors?.email?.message;
  const isAuth = pathname.startsWith("/auth");

  if (isAuth) {
    return null;
  }

  return (
    <footer className="mt-auto grid min-h-[156px] grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3">
      <div className="col-span-1 space-y-6 rounded-xl border-2 border-secondary p-6 lg:col-span-full xl:col-span-1">
        <div>
          <h2 className="mb-2 text-2xl font-medium text-white">Subscribe</h2>
          <p className="text-base font-normal text-foreground [text-wrap:_balance]">
            Subscribe to receive the latest LookIntoHex updates. No spam. You
            can unsubscribe at anytime.
          </p>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="relative">
            <input
              type="email"
              autoComplete="off"
              placeholder="Your email address"
              className={cn(
                "text-normal peer relative z-10 w-full rounded-full border border-secondary bg-primary py-5 pl-7 pr-36 text-base text-white placeholder:text-foreground focus:outline-none",
                emailError && "border-red-500",
              )}
              {...register("email")}
            />
            <div className="absolute -inset-px z-0 h-[calc(100%+2px)] w-[calc(100%+2px)] rounded-full bg-[linear-gradient(to_right,#ffdb01,#ff851f,#ff3d3d,#ff0f6f,#ff00ff)] opacity-0 transition-opacity duration-300 peer-focus:opacity-100" />
            <Button
              type="submit"
              className="!absolute right-3 top-2/4 z-10 -translate-y-2/4"
            >
              {isLoading ? (
                <span className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="mx-auto h-4 w-4 animate-spin text-hex-100"
                  >
                    <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                  </svg>
                  Collecting
                </span>
              ) : (
                "Subscribe"
              )}
            </Button>
          </form>
          {emailError && (
            <p className="mt-2 flex items-center gap-1.5 text-sm text-red-500">
              <ExclamationCircleIcon className="h-3.5 w-3.5 stroke-2" />{" "}
              {emailError}
            </p>
          )}
        </div>
      </div>
      <div className="relative flex flex-col justify-between gap-6 overflow-hidden rounded-xl border-2 border-secondary p-6">
        <Image
          src="https://pbs.twimg.com/media/GTJj1JTbsAAhEK4?format=jpg&name=large"
          alt="image"
          width={768}
          height={768}
          className="absolute right-0 top-0 h-full w-auto"
        />
        <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-primary via-primary via-60% to-primary/50 sm:via-85% lg:via-70% xl:via-60%" />
        <div className="relative z-10">
          <h2 className="mb-2 text-2xl font-medium text-white">
            Created by Gerardo
          </h2>
          <p className="text-base font-normal text-foreground">
            I hope you guys love what I&apos;ve built for Pulsechain.
          </p>
        </div>
        <Link
          target="_blank"
          href="https://twitter.com/gerawrdog"
          className="relative z-10 flex items-center gap-4 text-white transition-colors duration-300 hover:text-hex-400"
        >
          Follow on <X />
        </Link>
      </div>
      <div className="relative flex flex-col justify-between gap-6 rounded-xl border-2 border-secondary p-6">
        <div>
          <h2 className="mb-2 text-2xl font-medium text-white">
            Donation Address
          </h2>
          <p className="text-base font-normal text-foreground">
            Support LookIntoHex to keep the lights on, by donating to the
            following address.
          </p>
        </div>
        <Link
          target="_blank"
          href="https://scan.pulsechain.com/address/0x8A78FC4424919cE4ED44c45acDdf1fDfa177362B"
          className="relative z-10 break-all text-lg font-normal tracking-wide text-white transition-colors duration-300 hover:text-hex-400"
        >
          0x8A78FC4424919cE4ED44c45acDdf1fDfa177362B
        </Link>
        <div className="absolute right-8 top-2/4 flex -translate-y-2/4 items-center gap-6">
          <Ethereum />
          <Image
            src="/pulsechain.png"
            alt="PulseChain"
            width={512}
            height={512}
            className="aspect-square h-24 w-[6.5rem] opacity-20"
          />
        </div>
        {isCopied ? (
          <CheckIcon className="absolute right-3 top-3 h-6 w-6 cursor-pointer text-white transition-colors" />
        ) : (
          <Square2StackIcon
            onClick={() => {
              setIsCopied(true);
              copy("0x8A78FC4424919cE4ED44c45acDdf1fDfa177362B");
              toast.success("Address copied successfully.");
            }}
            className="absolute right-3 top-3 h-6 w-6 cursor-pointer text-white transition-colors duration-300 hover:text-hex-400"
          />
        )}
      </div>
    </footer>
  );
}

export default Footer;
