export function Ethereum() {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.1">
        <path
          d="M28.4167 62L64.5834 5.16666L100.75 62L64.5834 82.6666L28.4167 62Z"
          fill="#7880E7"
        />
        <path
          d="M64.5833 5.16666L100.75 62L64.5833 82.6666V5.16666Z"
          fill="#5C64C7"
        />
        <path
          d="M28.4167 69.75L64.5834 90.4167L100.75 69.75L64.5834 118.833L28.4167 69.75Z"
          fill="#7880E7"
        />
        <path
          d="M64.5834 90.4167L100.75 69.75L64.5834 118.833V90.4167ZM28.4167 62L64.5834 46.5L100.75 62L64.5834 82.6667L28.4167 62Z"
          fill="#5C64C7"
        />
        <path
          d="M64.5833 46.5L100.75 62L64.5833 82.6667V46.5Z"
          fill="#2A3192"
        />
      </g>
    </svg>
  );
}

export default Ethereum;
