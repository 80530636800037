import { CartesianGridProps } from "recharts";

export function gridConfig(options?: CartesianGridProps) {
  return {
    strokeOpacity: 1,
    stroke: "#34374680",
    strokeDasharray: 3,
    ...options,
  } as any;
}

export default gridConfig;
