"use client";

import * as React from "react";

// @utils
import { cn } from "@/utils";

interface EquationProps {
  className?: string;
  children: React.ReactNode;
}

export function Equation({ children, className }: EquationProps) {
  return (
    <div
      className={cn(
        "rounded-lg border-2 border-secondary bg-secondary/50 p-6 font-medium text-hex-400",
        className,
      )}
    >
      {children}
    </div>
  );
}

export default Equation;
