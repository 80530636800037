"use client";

import * as React from "react";

// @components
import { Button, Spinner } from "@/components";
import * as Dialog from "@radix-ui/react-dialog";

// @icons
import { XMarkIcon } from "@heroicons/react/24/outline";

export function GoPulseX() {
  const [isLoading, setIsLoading] = React.useState(true);

  return (
    <Dialog.Root onOpenChange={(open) => !open && setIsLoading(true)}>
      <Dialog.Trigger asChild>
        <Button variant="secondary" className="w-full sm:w-auto">
          Buy Hex
        </Button>
      </Dialog.Trigger>
      {/* @ts-ignore */}
      <Dialog.Portal className="relative">
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-40 bg-secondary/75 backdrop-blur-lg backdrop-saturate-200" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed left-1/2 top-1/2 z-50 h-[90vh] w-[90vw] max-w-[600px] -translate-x-1/2 -translate-y-1/2 rounded-xl border-2 border-secondary bg-black pt-12 shadow-2xl shadow-black/50 focus:outline-none">
          <div className="no-scrollbar h-full w-full overflow-y-scroll rounded-xl">
            {isLoading && (
              <div className="absolute inset-0 grid h-full w-full place-items-center rounded-xl bg-black">
                <Spinner />
              </div>
            )}
            <iframe
              src="https://gopulse.com/x/embed?out=HEX&r=0x8a78fc4424919ce4ed44c45acddf1fdfa177362b"
              allow="clipboard-read; clipboard-write"
              width="100%"
              height="100%"
              className="min-h-[800px] rounded-xl border-0"
              onLoad={() => setIsLoading(false)}
            ></iframe>
          </div>
          <Dialog.Close asChild>
            <button
              className="absolute right-2 top-2 inline-flex h-6 w-6 appearance-none items-center justify-center rounded-full text-white focus:outline-none"
              aria-label="Close"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default GoPulseX;
