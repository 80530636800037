import { TooltipProps } from "recharts";

export function tooltipConfig(options?: TooltipProps<any, any>) {
  return {
    labelFormatter: (value: string | number) => (
      <span className="inline-block pb-3 pt-1 text-base font-semibold text-white">
        Day {value}
      </span>
    ),
    formatter: (value: number) => new Intl.NumberFormat("en").format(value),
    contentStyle: {
      backgroundColor: "#050505",
      borderRadius: "8px",
      borderColor: "#1B1D1D",
      padding: "8px 16px",
      color: "#fff",
      fontWeight: 600,
      fontSize: "14px",
    },
    ...options,
  } as any;
}

export default tooltipConfig;
