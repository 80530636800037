"use client";

import * as React from "react";

// @utils
import { cn } from "@/utils";

interface ChartBlockProps {
  title?: string;
  isSmall?: boolean;
  isLoading: boolean;
  className?: string;
  isRounded?: boolean;
  watermark?: boolean;
  slot?: React.ReactNode;
  children: React.ReactNode;
  border?: "all" | "bottom" | "none";
}

export const ChartBlock = React.forwardRef<HTMLDivElement, ChartBlockProps>(
  (
    {
      title,
      isLoading,
      children,
      isSmall,
      slot,
      isRounded,
      border = "all",
      className,
      watermark = true,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          "group relative z-0 h-full select-none overflow-hidden",
          {
            "rounded-xl": isRounded,
          },
          className,
        )}
      >
        <div
          className={cn({
            "rounded-xl": isRounded,
            "overflow-visiblebefore:absolute relative isolate z-10 h-full before:inset-0 before:z-50 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/5 before:to-transparent":
              isLoading,
          })}
        >
          <div
            className={cn("w-full rounded-none bg-secondary/50 p-6", {
              "rounded-xl": isRounded,
              "animate-pulse": isLoading,
              "border-2 border-secondary": border === "all",
              "border-b-2 border-secondary": border === "bottom",
            })}
          >
            <div
              className={cn("mb-6 flex items-start justify-center gap-4", {
                "justify-between": slot,
              })}
            >
              {title && (
                <h2
                  className={cn(
                    "text-center text-xl font-semibold text-white md:text-2xl",
                    {
                      "text-left": slot,
                      "text-left text-lg md:text-xl": isSmall,
                    },
                  )}
                >
                  {title}
                </h2>
              )}
              {slot && slot}
            </div>
            <div
              className={cn("relative h-[24rem]", {
                "md:h-[32rem] lg:h-[40rem]": !isSmall,
              })}
            >
              {watermark && (
                <div
                  aria-hidden="true"
                  className="absolute left-1/2 top-6 -translate-x-1/2 text-lg font-bold text-foreground/15 md:text-3xl lg:text-4xl xl:text-5xl"
                >
                  LookIntoHex.com
                </div>
              )}
              {!isLoading && children}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

ChartBlock.displayName = "ChartBlock";

export default ChartBlock;
