import { YAxisProps } from "recharts";
import { scaleSymlog } from "d3-scale";

export function yAxisConfig(options?: YAxisProps) {
  return {
    scale: scaleSymlog(),
    stroke: "transparent",
    color: "transprent",
    width: 0,
    ...options,
  } as any;
}

export default yAxisConfig;
